import { Routes } from '@angular/router';

import { Pages } from './pages.component';
import { GodGuard } from '../../helpers/guards';
import { awareAuthGuard } from '@appbolaget/aware-auth';

export const routes: Routes = [
    {
        path: 'login',
        redirectTo: '/auth/login',
    },
    {
        path: 'login/new-password/:token',
        redirectTo: '/auth/new-password/:token',
    },
    {
        path: 'login/verify-password/:token',
        redirectTo: '/auth/verify-password/:token',
    },
    {
        path: 'login/delete-client/:token',
        redirectTo: '/auth/delete-client/:token',
    },
    {
        path: 'login/forgot-password',
        redirectTo: '/auth/forgot-password',
    },
    {
        path: 'auth',
        loadChildren: () => import('@modules/auth/auth.routing').then((m) => m.routes),
    },
    {
        path: 'app',
        canActivate: [awareAuthGuard],
        component: Pages,
        children: [
            { path: '', redirectTo: 'me', pathMatch: 'full' },
            {
                path: 'fields',
                loadChildren: () => import('app/views/pages/fields/fields.routing').then((m) => m.routes),
            },
            {
                path: 'calendar',
                loadChildren: () => import('@pages/calendar/calendar.routing').then((m) => m.routes),
            },
            {
                path: 'clients',
                loadChildren: () => import('@pages/clients/clients.routing').then((m) => m.routes),
            },
            {
                path: 'locations',
                loadChildren: () => import('@pages/locations/locations.routing').then((m) => m.routes),
            },
            {
                path: 'me',
                loadChildren: () => import('@pages/clients/show/show.routing').then((m) => m.routes),
                data: {
                    isMe: true,
                },
            },
            {
                path: 'messages',
                loadChildren: () => import('@pages/messages/messages.routes').then((c) => c.routes),
            },
            {
                path: 'roles',
                loadChildren: () => import('@pages/olympus/roles/roles.routing').then((m) => m.routes),
                canActivate: [GodGuard],
            },
            {
                path: 'posts',
                loadChildren: () => import('@pages/posts/posts.routing').then((m) => m.routes),
            },
            {
                path: 'categories',
                loadChildren: () => import('@pages/categories/categories.routing').then((m) => m.routes),
            },
            {
                path: 'sights',
                loadChildren: () => import('@pages/posts/posts.routing').then((m) => m.routes),
                data: { type: 'sight' },
            },
            {
                path: 'approutes',
                loadChildren: () => import('@pages/posts/posts.routing').then((m) => m.routes),
                data: { type: 'approute' },
            },
            {
                path: 'events',
                loadChildren: () => import('@pages/posts/posts.routing').then((m) => m.routes),
                data: { type: 'event' },
            },
            {
                path: 'occasions',
                loadChildren: () => import('@pages/posts/posts.routing').then((m) => m.routes),
                data: { type: 'occasion' },
            },
            {
                path: 'pages',
                loadChildren: () => import('@pages/posts/posts.routing').then((m) => m.routes),
                data: { type: 'page' },
            },
            {
                path: 'news',
                loadChildren: () => import('@pages/posts/posts.routing').then((m) => m.routes),
                data: { type: 'news' },
            },
            {
                path: 'stories',
                loadChildren: () => import('@pages/posts/posts.routing').then((m) => m.routes),
                data: { type: 'story' },
            },
            {
                path: 'noticeboard',
                loadChildren: () => import('@pages/posts/posts.routing').then((m) => m.routes),
                data: { type: 'noticeboard' },
            },
            {
                path: 'navigations',
                loadChildren: () => import('@pages/navigation/navigation.routing').then((m) => m.routes),
            },
            {
                path: 'units',
                loadChildren: () => import('@pages/units/units.routing').then((m) => m.routes),
            },
            {
                path: 'olympus',
                loadChildren: () => import('@pages/olympus/olympus.routing').then((m) => m.routes),
                canActivate: [GodGuard],
            },
            {
                path: 'organisations',
                loadChildren: () => import('@pages/olympus/organisations/organisations.routing').then((m) => m.routes),
                canActivate: [GodGuard],
            },
            {
                path: 'notifications',
                loadChildren: () => import('@pages/notification/notification.routing').then((m) => m.routes),
            },
            {
                path: 'waste',
                loadChildren: () => import('app/views/pages/waste/waste.routing').then((m) => m.routes),
            },
            {
                path: 'lunch',
                redirectTo: '/app/posts?type=lunch_week',
            },
            {
                path: 'contracts',
                loadChildren: () => import('@modules/contracts/contracts.routing').then((m) => m.routes),
            },
            {
                path: 'sorting',
                loadChildren: () => import('@modules/sorting/sorting.routing').then((m) => m.routes),
            },
            {
                path: 'waste-deviations',
                loadChildren: () => import('@modules/waste-deviations/waste-deviations.routing').then((m) => m.routes),
            },
            {
                path: 'feedback',
                loadChildren: () => import('@modules/feedback/feedback.routing').then((m) => m.routes),
            },
            {
                path: 'transactions',
                loadChildren: () => import('@pages/transactions/transactions.routing').then((m) => m.routes),
            },
            {
                path: 'contracts',
                loadChildren: () => import('@modules/contract/contract.routing').then((m) => m.routes),
            },
            {
                path: 'iuc-companies',
                loadChildren: () => import('@modules/iuc-companies/companies.routing').then((m) => m.routes),
            },
            {
                path: 'iuc-categories',
                loadChildren: () => import('@modules/iuc-categories/categories.routing').then((m) => m.routes),
            },
            // {
            //     path: 'contracts',
            //     loadChildren: () => import('@modules/contract/contract.module').then((m) => m.ContractModule),
            // },
            { path: '**', redirectTo: 'me', pathMatch: 'full' },
        ],
    },
];
