import { inject } from '@angular/core';
import { AwareAuthIdleConfig, AwareAuthIdleConfigProvider, AwareAuthService } from '@appbolaget/aware-auth';
import { Unit } from '@appbolaget/aware-model';
import { Observable, distinctUntilChanged, filter, map } from 'rxjs';

export class IdleConfigProvider implements AwareAuthIdleConfigProvider {
    #awareAuth = inject(AwareAuthService);

    getConfig(): Observable<AwareAuthIdleConfig> {
        return this.#awareAuth.state$.pipe(
            filter((state) => (state.stateChecked && state.token && state.refreshToken && state.client?.units ? true : false)),
            map((state) => state.client.units),
            distinctUntilChanged(),
            map((units) => {
                const idleConfigFromUnits = this.#getIdleConfigFromUnits(units);
                if (idleConfigFromUnits) {
                    return idleConfigFromUnits;
                }

                return new AwareAuthIdleConfig({ enabled: false });
            }),
        );
    }

    #getIdleConfigFromUnits(units: Unit[]): AwareAuthIdleConfig | null {
        let config = null;

        const getUnitConfig = (unit: Unit): AwareAuthIdleConfig | null => {
            if (unit.config) {
                try {
                    const parsedConfig = JSON.parse(unit.config);
                    const idleConfig = parsedConfig?.panel?.auth?.idle;
                    if (idleConfig && idleConfig?.timeoutInMinutes && idleConfig?.warningInMinutesBeforeTimeout) {
                        return new AwareAuthIdleConfig(idleConfig);
                    }
                } catch (e) {
                    if (unit.parent) {
                        return getUnitConfig(unit.parent);
                    }

                    return null;
                }
            }

            if (unit.parent) {
                return getUnitConfig(unit.parent);
            }

            return null;
        };

        for (const unit of units) {
            const unitConfig = getUnitConfig(unit);
            if (unitConfig) {
                config = unitConfig;
                break;
            }
        }

        return config;
    }
}
