import { TreeModule } from '@ali-hm/angular-tree-component';
import { IMAGE_LOADER, ImageLoaderConfig } from '@angular/common';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { MatMomentDateModule, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { Router, provideRouter } from '@angular/router';
import {
    AwareAuthHttpInterceptor,
    AwareAuthService,
    provideAwareAuth,
    AwareAuthState,
    AWARE_AUTH_IDLE_CONFIG,
} from '@appbolaget/aware-auth';
import { AwareExtensionProvider, provideAwareExtension } from '@appbolaget/aware-extension';
import { AwareHttpService, provideAwareHttp, AwareHttpRequestModule } from '@appbolaget/aware-http';
import { AwareSecurityPermissionProvider, provideAwareSecurity } from '@appbolaget/aware-security';
import { MediaDialogService } from '@components/media';
import { environment } from '@env';
import { FullCalendarModule } from '@fullcalendar/angular';
import { GodGuard, GuestGuard } from '@helpers/guards';
import { NotificationService } from '@modules/notification/notification.service';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { Store, provideStore } from '@ngxs/store';
import { MessagesService } from '@pages/messages/messages.service';
import { AppsService } from '@pages/olympus/apps/apps.service';
import { RoleState } from '@pages/olympus/roles/state/role.state';
import { FileDownloaderService, FileUploaderService } from '@services/http';
import { DialogService } from '@viewservices/dialog.service';
import { LanguageService } from '@viewservices/language.service';
import { SnackbarService } from '@viewservices/snackbar.service';
import { provideNgxWebstorage, withLocalStorage, withNgxWebstorageConfig } from 'ngx-webstorage';
import { routes } from './app.routing';
import { Config, VersionService, PermissionProvider, ExtensionProvider } from './services';
import { AppState } from './state/app.state';
import { customStateSerializer, customStateDeserializer } from './state/state.serializer';
import { UnitState } from './state/unit.state';
import { DragDropModule } from '@modules/dragdrop/dragdrop.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Subject, takeUntil, filter, tap, switchMap, take, delay, of, catchError } from 'rxjs';
import { SetActiveUnitIfNotSet } from './state/unit.actions';
import { QrCodeModule } from 'ng-qrcode';
import * as Sentry from '@sentry/angular';
import { AwareSentryProvider, provideAwareSentry } from '@appbolaget/sentry';
import { SentryProvider } from '@services/sentry.provider';
import { AwareSentryHttpInterceptor } from '@appbolaget/sentry';
import { IdleConfigProvider } from '@modules/auth/idle-config.provider';
import { MaterialDateAdapter } from '@helpers/adapters';
import { provideNotificationModule } from '@modules/notification/notification.module';
import 'moment/locale/sv';
import moment from 'moment';
moment.locale('sv');

const awareImageLoader = (config: ImageLoaderConfig) => {
    let url = `${environment.api.url}/@media/${config.src}&`;
    let queryParams = [];
    if (config.width) {
        queryParams.push(`w=${config.width}`);
    }

    if (config.loaderParams?.height) {
        queryParams.push(`h=${config.loaderParams.height}`);
    }

    return url + queryParams.join('&');
};

export const applicationConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom(
            BrowserModule,
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useFactory: createTranslateLoader,
                    deps: [HttpClient],
                },
            }),
            DragDropModule.forRoot(),
            FullCalendarModule,
            MatMomentDateModule,
            MatProgressBarModule,
            MatSnackBarModule,
            MatDialogModule,
            TreeModule,
            NgxsLoggerPluginModule.forRoot({
                disabled: environment.production,
            }),
            NgxsStoragePluginModule.forRoot({
                keys: ['app.api_env', UnitState],
                serialize: customStateSerializer,
                deserialize: customStateDeserializer,
            }),
            QrCodeModule,
        ),
        AppsService,
        Config,
        DialogService,
        FileDownloaderService,
        FileUploaderService,
        GodGuard,
        GuestGuard,
        MediaDialogService,
        MessagesService,
        VersionService,
        LanguageService,
        SnackbarService,
        TranslateService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AwareAuthHttpInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AwareSentryHttpInterceptor,
            multi: true,
        },
        { provide: LOCALE_ID, useValue: 'sv-SE' },
        { provide: MAT_DATE_LOCALE, useValue: 'sv-SE' },
        {
            provide: DateAdapter,
            useClass: MaterialDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: AwareSecurityPermissionProvider, useClass: PermissionProvider },
        { provide: AwareExtensionProvider, useClass: ExtensionProvider },
        { provide: AwareSentryProvider, useClass: SentryProvider },
        { provide: AWARE_AUTH_IDLE_CONFIG, useClass: IdleConfigProvider },
        // { provide: AWARE_AUTH_ASYNC_STORAGE_ENGINE, useClass: AwareAuthLocalStorageEngine, },
        {
            provide: IMAGE_LOADER,
            useValue: awareImageLoader,
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                logErrors: true,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appInit,
            deps: [Store, AwareHttpService, AwareAuthService, NotificationService, MatIconRegistry, Sentry.TraceService],
            multi: true,
        },
        provideAwareAuth({
            loginRoute: '/auth/login',
        }),
        provideAwareHttp({
            defaultEnv: {
                name: environment.envName,
                url: environment.api.url,
                unit: environment.api.unit,
            },
            defaultHeaders: {
                source: environment.api.source,
                language: 'sv',
                'language-fallback': 'sv',
                location: 'se',
                timezone: 'Europe/Stockholm',
                hidden: '*',
                module: AwareHttpRequestModule.Cms,
                unit: environment.api.unit,
            },
        }),
        provideStore([AppState, UnitState, RoleState], {
            developmentMode: !environment.production,
        }),
        provideAwareSecurity(),
        provideAwareExtension(),
        provideAwareSentry(),
        provideNotificationModule(),
        provideAnimations(),
        provideRouter(routes),
        provideNgxWebstorage(withNgxWebstorageConfig({ prefix: 'aw', separator: '.', caseSensitive: true }), withLocalStorage()),
        provideHttpClient(withInterceptorsFromDi()),
    ],
};

function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=9');
}

function appInit(
    store: Store,
    api: AwareHttpService,
    awareAuth: AwareAuthService,
    _: NotificationService,
    matIconRegistry: MatIconRegistry,
    __: Sentry.TraceService,
) {
    matIconRegistry.setDefaultFontSetClass('material-icons-outlined');

    return async () => {
        const initted$ = new Subject<void>();

        return await store
            .select(AppState.initialData)
            .pipe(
                takeUntil(initted$),
                filter((state) => state && state.state_init),
                tap((appState) => {
                    if (appState.api_env) {
                        api.setApiEnv(appState.api_env);
                    }
                }),
                switchMap((_) =>
                    awareAuth.state$.pipe(
                        filter((state) => state.stateChecked),
                        take(1),
                        delay(100),
                    ),
                ),
                switchMap((authState: AwareAuthState) => {
                    if (authState?.client && authState?.token && authState?.refreshToken) {
                        return store.dispatch(new SetActiveUnitIfNotSet(authState.client.units));
                    }

                    return of(true);
                }),
                catchError(() => {
                    awareAuth.logout();
                    return of(true);
                }),
                tap(() => {
                    initted$.next();
                }),
            )
            .toPromise();
    };
}
