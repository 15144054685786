<div
  class="flex items-start justify-center fixed top-0 left-0 w-full h-full bg-black/20 z-50 p-3"
  [class.hidden]="!UNIT_SEARCH_VISIBLE"
  (click)="toggleUnitSearch()"
>
  <div class="flex flex-col w-full md:w-[500px] bg-white rounded-lg shadow-lg mt-[300px]" (click)="$event.stopPropagation()">
    <mat-form-field appearance="fill" subscriptSizing="dynamic">
      <mat-label>Sök enhet</mat-label>
      <input
        matInput
        type="search"
        #unitSearchInput
        (keydown.escape)="toggleUnitSearch()"
        [matAutocomplete]="auto"
        [formControl]="unitSearchControl"
      />
    </mat-form-field>
    <mat-progress-bar color="primary" mode="indeterminate" [hidden]="!IS_LOADING"></mat-progress-bar>

    <mat-autocomplete
      autoActiveFirstOption
      #auto="matAutocomplete"
      [displayWith]="displayFn"
      [class]="'Alfred'"
      (optionSelected)="setActiveUnit($event)"
    >
      @for (unit of (units$ | async)?.data; track unit) {
        <mat-option [value]="unit" class="flex items-center">
          <div class="flex flex-col">
            @if (unit.parent) {
              <span class="text-sm text-gray-500 leading-4 mb-1">{{ unit.parent.title }}</span>
            }
            <span class="leading-4 font-medium">{{ unit.title }}</span>
          </div>
        </mat-option>
      }
    </mat-autocomplete>
  </div>
</div>
