import { Client, Unit, serializeAwareModel } from '@appbolaget/aware-model';

export function customStateDeserializer(data) {
    if (typeof data !== 'string') {
        return data;
    }

    const parsed = JSON.parse(data);

    if (parsed.client) {
        parsed.client = new Client(parsed.client);
    }

    if (parsed.hijacker?.client) {
        parsed.hijacker.client = new Client(parsed.hijacker.client);
    }

    if (parsed.active_unit) {
        parsed.active_unit = new Unit(parsed.active_unit);
    }

    if (parsed.available_units) {
        parsed.available_units = parsed.available_units.map((unit) => new Unit(unit));
    }

    return parsed;
}

export function customStateSerializer(data) {
    if (data?.client) {
        data = {
            ...data,
            client: serializeAwareModel(data.client),
        };
    }

    if (data?.active_unit) {
        data = {
            ...data,
            active_unit: serializeAwareModel(data.active_unit),
        };
    }

    if (data?.hijacker?.client) {
        data = {
            ...data,
            hijacker: {
                ...data.hijacker,
                client: serializeAwareModel(data.hijacker.client),
            },
        };
    }

    return JSON.stringify(data);
}
