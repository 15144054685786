import { NgModule, ModuleWithProviders } from '@angular/core';
import { Draggable } from './directives/draggable.directive';
import { Droppable } from './directives/droppable.directive';
import { DragDropService } from './services/dragdrop.service';

@NgModule({
    imports: [Draggable, Droppable],
    exports: [Draggable, Droppable],
})
export class DragDropModule {
    static forRoot(): ModuleWithProviders<DragDropModule> {
        return {
            ngModule: DragDropModule,
            providers: [DragDropService],
        };
    }
}
