import { Injectable, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { UnitState } from 'app/state/unit.state';
import { AwareAuthService } from '@appbolaget/aware-auth';
import { AwareSentryProvider } from '@appbolaget/sentry';
import { Unit, Client } from '@appbolaget/aware-model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SentryProvider implements AwareSentryProvider {
    #store = inject(Store);
    #awareAuth = inject(AwareAuthService);

    getUnit(): Observable<Unit> {
        return this.#store.select(UnitState.activeUnit);
    }

    getClient(): Observable<Client | null> {
        return this.#awareAuth.client$;
    }
}
