<h2 mat-dialog-title>Är du där?</h2>
<mat-dialog-content>
  <div class="flex flex-col gap-4">
    <span>Din sessionstid håller på att löpa ut. Vill du fortfarande vara inloggad?</span>
    @if (durationRemaining$ | async; as duration) {
      <span class="font-bold"
        >Tid kvar: {{ duration.hours ? duration.hours + 'h ' : '' }} {{ duration.minutes ? duration.minutes + 'm ' : '' }}
        {{ duration.seconds ? duration.seconds + 's ' : '' }}</span
      >
    }
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="flex justify-end gap-2 w-full">
    <button mat-button (click)="logout()">Nej, logga ut mig</button>
    <button mat-raised-button color="primary" (click)="stayLoggedIn()">Ja, håll mig inloggad</button>
  </div>
</mat-dialog-actions>
